import React, { useEffect, useState } from "react";
import "./../DashboardPanel/DashboardPanel.css";
import MailTemplates from "../MailTemplates/MailTemplates";
import { useNavigate } from "react-router-dom";
import yellowletterhq from "./../../Assets/yellowletterhq.png";

import img1 from "./../../Assets/Group.png";
import img2 from "./../../Assets/Group 46.png";

import dashboardlogo from "./../../Assets/dashboard logo.png";
import userlogo from "./../../Assets/user logo.png";
import helplogo from "./../../Assets/help logo.png";
import logoutlogo from "./../../Assets/logout logo.png";
import arrowright from "./../../Assets/qarrow logo.png";
import NavbarDashboard from "../NavbarDashboard/NavbarDashboard";
function DashboardPanel() {
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    try {
      localStorage.removeItem("password");
      localStorage.removeItem("email");
      // navigate("/");
    } catch (error) {
      console.error("Error removing items from localStorage:", error);
    }
  };

  return (
    <>
      <>
        <NavbarDashboard />
      </>
      <div>
        <div id="wrapper">
          <ul
            className="navbar-nav bg-gradient-dark sidebar sidebar-dark accordion"
            id="accordionSidebar"
            style={{ background: "#2E3547" }}
          >
            <a className="sidebar-brand d-flex ">
              <div className="sidebar-brand-text mt-2">
                <img src={dashboardlogo} className="dashboard_logo" />
                <span className="mx-2">Dashboard</span>
              </div>
            </a>

            {/* <div class="dropdown">
              <button
                class="btn_dropdown dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                  <img src={dashboardlogo} className="dashboard_logo" />
                  <span className="sidebar_text mx-2">Email Templates</span>    
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" onClick={() => handleMenuItemClick("mainMenu")}>
                    Mail Templates
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Streetview Templates{" "}
                  </a>
                </li>
              </ul>
            </div> */}

            <a
              className="sidebar-brand d-flex "
              onClick={() => handleMenuItemClick("mainMenu")}
            >
              <div className="sidebar-brand-text mt-2">
                <img src={userlogo} className="dashboard_logo" />
                <span className="sidebar_text mx-2"> Mail Templates</span>
              </div>
            </a>
            {/* <div class="dropdown">
              <button
                class="btn_dropdown dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={userlogo} className="dashboard_logo" />
                        <span className="sidebar_text mx-2">User</span> 
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="#">
                    Mail Templates
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Streetview Templates{" "}
                  </a>
                </li>
              </ul>
            </div> */}

            <a className="sidebar-brand d-flex ">
              <div className="sidebar-brand-text mt-2">
                <img src={userlogo} className="dashboard_logo" />
                <span className="sidebar_text mx-2">user</span>
              </div>
            </a>

            <a className="sidebar-brand d-flex ">
              <div className="sidebar-brand-text mt-2">
                <img src={helplogo} className="dashboard_logo" />
                <span className="sidebar_text mx-2">help</span>
              </div>
            </a>

            <hr className="sidebar-divider" />

            <li className="nav-item " onClick={handleLogout}>
              <a className="nav-link" href="/">
                <span className="sidebar_text mx-2">
                  {" "}
                  <img src={logoutlogo} className="dashboard_logo" />
                  <span className="sidebar_text mx-2">Logout</span>
                </span>
              </a>
            </li>

            <div className="text-center d-none d-md-inline my-5">
              <button
                className="rounded-circle border-0"
                id="sidebarToggle"
              ></button>
            </div>
          </ul>

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <nav className="navbar navbar-expand navbar-light bg-white topbar mb-5 static-top shadow">
                <button
                  id="sidebarToggleTop"
                  className="btn btn-link d-md-none rounded-circle mr-3"
                >
                  <i className="fa fa-bars"></i>
                </button>
              </nav>

              {activeMenuItem ? " " : <MailTemplates />}

              {activeMenuItem === "mainMenu" && <MailTemplates />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardPanel;
