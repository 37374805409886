import React, { useEffect, useState } from "react";
import "./../NavbarDashboard/NavbarDashboard.css";
import yellowletterhq from "./../../Assets/yellowletterhq.png";

import img1 from "./../../Assets/Group.png";
import img2 from "./../../Assets/Group 46.png";

function NavbarDashboard() {
  const collpasework = () => {
    const collapseElements = document.querySelectorAll(".collapse");
    collapseElements.forEach((element) => {
      new window.bootstrap.Collapse(element);
    });
  };
  useEffect(() => {
    const handleSidebarToggle = () => {
      document.body.classList.toggle("sidebar-toggled");
      document.querySelector(".sidebar").classList.toggle("toggled");

      if (document.querySelector(".sidebar").classList.contains("toggled")) {
        const collapseElements =
          document.querySelectorAll(".sidebar .collapse");
        collapseElements.forEach((element) => {
          const bootstrapCollapse = new window.bootstrap.Collapse(element);
          bootstrapCollapse.hide();
        });
      }
    };

    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        const collapseElements =
          document.querySelectorAll(".sidebar .collapse");
        collapseElements.forEach((element) => {
          const bootstrapCollapse = new window.bootstrap.Collapse(element);
          bootstrapCollapse.hide();
        });
      }

      if (
        window.innerWidth < 480 &&
        !document.querySelector(".sidebar").classList.contains("toggled")
      ) {
        document.body.classList.add("sidebar-toggled");
        document.querySelector(".sidebar").classList.add("toggled");

        const collapseElements =
          document.querySelectorAll(".sidebar .collapse");
        collapseElements.forEach((element) => {
          const bootstrapCollapse = new window.bootstrap.Collapse(element);
          bootstrapCollapse.hide();
        });
      }
    };

    document
      .querySelector("#sidebarToggle")
      .addEventListener("click", handleSidebarToggle);
    document
      .querySelector("#sidebarToggleTop")
      .addEventListener("click", handleSidebarToggle);
    window.addEventListener("resize", handleWindowResize);

    return () => {
      document
        .querySelector("#sidebarToggle")
        .removeEventListener("click", handleSidebarToggle);
      document
        .querySelector("#sidebarToggleTop")
        .removeEventListener("click", handleSidebarToggle);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top navbarsticky">
        <div className="">
          <img src={yellowletterhq} className="ppc_logo_navbar_dashboard" />
        </div>

        {/* <a className="navbar-brand" href="#">Navbar</a> */}
        <button
          className="navbar-toggler bg-light m-3"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={collpasework}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <form
            className="form-inline  my-2 my-lg-0 navbar_main_div_ppc_dashboard "
            onSubmit={(e) => e.preventDefault()}
          >
            <img src={img1} />

            <p className="total_balance">
              Total Balance
              <br /> <p className="dollar_price">$0.00</p>
            </p>
            <div className="vl"></div>
            <img src={img2} />

            <p className="name_user_style">Tango tag</p>
            <button className="btn_user_name"></button>
          </form>
        </div>
      </nav>
    </>
  );
}

export default NavbarDashboard;
