import React, { useEffect, useState } from "react";
import "./../MailTemplates/MailTemplates.css";
import searchicon from "./../../Assets/search_icon.png";
import mini from "./../../Assets/mini.PNG";
import pdf from "./../../Assets/Group 48.png";
import heart from "./../../Assets/heart--reward-social-rating-media-heart-it-like-favorite-love.png";
import cycle from "./../../Assets/Group 47.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function MailTemplates() {
  const token = localStorage.getItem("token");
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const [templates, setTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [modalUrl, setModalUrl] = useState("");

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = () => {
    axios
      .get("/api/templates", {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PATCH,OPTIONS",
        },
      })
      .then((res) => {
        setTemplates(res.data);
        console.log(res?.data);
        setFilteredTemplates(res.data); // Initially, set filteredTemplates to all templates
      })
      .catch((error) => {
        console.error("Error fetching templates:", error);
      });
  };

  const handleDownload = (pdfFileName) => {
    const fileUrl = `${process.env.REACT_APP_BASE_URL}/storage/${pdfFileName}`;
    window.open(fileUrl, "_blank");
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    if (templates) {
      const filtered = templates.filter(
        (template) =>
          (template.template_id && template.template_id.includes(searchTerm)) ||
          (template.template_name &&
            template.template_name.includes(searchTerm))
      );
      setFilteredTemplates(filtered);
    }
  };

  const handleCopyUrl = () => {
    const copyText = document.getElementById("urlInput");
    copyText.select();
    document.execCommand("copy");
  };

  // const generateUrl = (templateId) => {
  //   return `http://127.0.0.1:8000/api/mail/lzYNPPRZiucSeNXd10se3F0RekpAYSav/${templateId}`;
  // };

  const generateUrl = (templateId) => {
    return `${process.env.REACT_APP_BASE_URL}/api/mail/lzYNPPRZiucSeNXd10se3F0RekpAYSav/S${templateId}`;
  };

  return (
    <div className="main_mail_template">
      <p className="Mail_Templates_heading">Mail Templates</p>

      <div className="d-flex flex-row">
        <div className="input_search_div">
          <div className="d-flex flex-row">
            <img src={searchicon} className="search_icon" />
            <input
              placeholder="Template name or ID"
              className="tempalet_input_id"
              onChange={handleSearch}
              value={searchTerm}
            />
          </div>
        </div>

        <div className="dropdown">
          <button
            className="dropdown_category dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Category
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item" href="#">
                1
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                2
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="d-flex flex-row">
        <input
          type="checkbox"
          id="permission"
          name="permission"
          value="permission"
          className="checkbox_style_view_favourite"
          required
        />

        <label htmlFor="permission" className="label_checkbox_view_favourite">
          Show Favorites Only
        </label>
        <br />
      </div>

      <div className="main_white_div_for_cards">
        <div className="row">
          {filteredTemplates
            ? filteredTemplates.map((data, index) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                  <div className="main_div_template">
                    <div className="d-flex justify-content-between">
                      <img src={mini} className="mini_style_icon" />

                      <div className="d-flex flex-row">
                        <img src={heart} className="heart_icon_style" />
                        <img
                          src={pdf}
                          className="heart_icon_style"
                          onClick={() => handleDownload(data.pdf_file)}
                          alt="PDF Download"
                        />
                        <img
                          src={cycle}
                          className="heart_icon_style"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() =>
                            setModalUrl(generateUrl(data.template_id))
                          }
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="investor_name_para">
                          01 Investor Buys Houses
                        </p>
                        <p className="id_investor_para">
                          ID: S{data?.template_id}
                        </p>
                      </div>

                      <button className="btn_order_para">Order</button>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                URL
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <input
                className="input_style_url"
                placeholder="URL"
                id="urlInput"
                value={modalUrl}
                readOnly
              />
            </div>
            <div class="modal-footer">
              <button
                className="copy_url_style_btn"
                onClick={handleCopyUrl}
                data-bs-dismiss="modal"
              >
                Copy URL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MailTemplates;
