import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useHistory from react-router-dom

import "./../LoginPage/Login.css";
import eye from "./../Assets/visible--eye-eyeball-open-view.png";
import at from "./../Assets/at.png";
import password_icon from "./../Assets/Union.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to control password visibility

  const navigate = useNavigate();
  const handleLogin = () => {
    // Check if entered email and password match
    if (email == "yellowletterhq@gmail.com" && password == "yellowletterhq12") {
      // If match, navigate to the desired page
      localStorage.setItem("email", "yellowletterhq@gmail.com");
      localStorage.setItem("password", "yellowletterhq12");
      navigate("/dashboard"); // Replace '/other-page' with the path to the other page
    } else {
      // If not match, you can display an error message or handle it as needed
      alert("Invalid email or password");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the showPassword state
  };

  return (
    <div className="main_div_login">
      <div className="center_div">
        <form onSubmit={handleLogin}>
          <div className="yellow_div">
            <div className="d-flex justify-content-center">
              <p className="login_para">LOGIN</p>
            </div>

            <div className="div_style_login">
              <div className="row">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                  <img className="at_style" src={at} alt="@" />
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                  <input
                    className="input_style_login"
                    placeholder="Email*"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="div_style_login_password">
              <div className="row">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                  <img
                    className="at_style"
                    src={password_icon}
                    alt="password"
                  />
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                  <input
                    className="input_style_login"
                    placeholder="Password*"
                    type={showPassword ? "text" : "password"} // Toggle between "text" and "password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
                  <img
                    src={eye}
                    className="eye_style"
                    alt="show password"
                    onClick={togglePasswordVisibility} // Call togglePasswordVisibility function on click
                  />
                </div>
              </div>
            </div>

            <div className="main_div_forgot_para">
              <div className="d-flex justify-content-end">
                <p className="para_forgot">Forget your password ?</p>
              </div>
            </div>

            <div className="main_div_forgot_para">
              <p className="para_red"> Please accept the terms of service</p>

              <div className="row">
                <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                  <input
                    type="checkbox"
                    id="permission"
                    name="permission"
                    value="permission"
                    className="checkbox_style"
                    required
                  />
                </div>
                <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                  <label htmlFor="permission" className="label_checkbox">
                    Click here to accept <b>Terms of Services</b> &{" "}
                    <b>Privacy Policy</b>
                  </label>
                  <br />
                </div>
              </div>
              <button className="btn_login" type="submit">
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
