import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import BrowserRouter as Router

import Login from "./LoginPage/Login";
import DashboardPanel from "./Dashboard/DashboardPanel/DashboardPanel";
import MailTemplates from "./Dashboard/MailTemplates/MailTemplates";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />

          <Route path="/dashboard" element={<DashboardPanel />} />
          <Route path="/dashboard/mail-tempaltes" element={<MailTemplates />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
